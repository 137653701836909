@tailwind base;
@tailwind components;
@tailwind utilities;

/* Ensure consistent text inheritance for all elements */
* {
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  font-family: inherit;
}

body {
  @apply base-regular;
}

/* global font styles */
.markdown-content,
.tiptap {
  font-size: 14px;
}

/* Ensure links are wrapped based on the width of the container */
a {
  @apply break-all;
}

/* Global overrides */
.markdown-content ul,
.tiptap ul {
  @apply list-outside list-disc;
  margin-top: 2px;
}

.markdown-content ul li,
.tiptap ul li {
  margin-left: 1em;
  padding: 1px 0 1px 0;
  display: list-item;
  text-align: -webkit-match-parent;
}

.markdown-content ol,
.tiptap ol {
  @apply list-outside list-decimal pl-5;
}

.markdown-content ol li,
.tiptap ol li {
  padding: 4px 0 4px 0;
}

/* TODO: fix paragraph styling inside markdown content*/
/* .markdown-content p {
  @apply flex flex-col gap-2;
} */

.tiptap {
  min-height: 48px;
  max-height: 50vh;
  overflow-y: auto;
}

/* 

Nested list styles */
/* First level */
.markdown-content ul,
.tiptap ul {
  list-style-type: disc;
}

/* Second level */
.markdown-content ul ul,
.tiptap ul ul {
  list-style-type: circle;
}

/* Third level */
.markdown-content ul ul ul,
.tiptap ul ul ul {
  list-style-type: square;
}

/* Fourth level - restart cycle */
.markdown-content ul ul ul ul,
.tiptap ul ul ul ul {
  list-style-type: disc;
}

/* Fifth level */
.markdown-content ul ul ul ul ul,
.tiptap ul ul ul ul ul {
  list-style-type: circle;
}

/* Sixth level */
.markdown-content ul ul ul ul ul ul,
.tiptap ul ul ul ul ul ul {
  list-style-type: square;
}

/* Ordered list levels */
/* First level */
.markdown-content ol,
.tiptap ol {
  list-style-type: decimal;
}

/* Second level */
.markdown-content ol ol,
.tiptap ol ol {
  list-style-type: lower-alpha;
}

/* Third level */
.markdown-content ol ol ol,
.tiptap ol ol ol {
  list-style-type: lower-roman;
}

/* Fourth level */
.markdown-content ol ol ol ol,
.tiptap ol ol ol ol {
  list-style-type: decimal;
}

/* Fifth level */
.markdown-content ol ol ol ol ol,
.tiptap ol ol ol ol ol {
  list-style-type: lower-alpha;
}

/* Sixth level */
.markdown-content ol ol ol ol ol ol,
.tiptap ol ol ol ol ol ol {
  list-style-type: lower-roman;
}

.markdown-content blockquote,
.tiptap blockquote {
  @apply border-l-2 border-gray-300 pl-4;
}

/* Placeholder for empty editor */
.tiptap p.is-editor-empty:first-child::before {
  color: #6b7280;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

@layer utilities {
  .scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .scrollbar-hide::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }
}
